import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet típic amb capell i peu. El capell pot fer fins a 7 cm de diàmetre, primer en forma de tub, després s’obre i queda umbilicat al centre. El color és variable, del terrós groguenc al gris marró amb tons taronja viu. A la part inferior del capell té una espècie de venes més o menys marcades, com si fossin làmines rudimentàries de color taronjós groguenc. El peu és llarg, cilíndric de color groc daurat, un poc més fosc cap al capell i més clar, fins i tot blanquinós a la base. Les espores són blanques o color crema, el·lípsoides, de 10-12 x 7-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      